<template>
  <b-col lg="6" md="12" class="blocked-user-card">
    <div class="blocked-user-card-inner">
      <div class="blocked-user-left">
        <router-link :to="{name: 'users.show', params: {id: user.id}}">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="48"
                  :src="user.profileImage"
                  :text="avatarText(user.userName)"
              />
            </template>
          </b-media>
        </router-link>
        <div class="blocked-user-details">
          <router-link :to="{name: 'users.show', params: {id: user.id}}" class="blocked-user-link">
            @{{ user.userName }}
          </router-link>
        </div>
      </div>
      <div class="blocked-user-right">
        <b-button size="sm" variant="light" class="fs-14" @click="unBlockUser">
          {{ $t('Unblock') }}
        </b-button>
      </div>
    </div>
  </b-col>
</template>

<script>
import { BAvatar, BButton, BCol, BImg, BMedia } from 'bootstrap-vue'
import moment from 'moment/moment'
import { avatarText } from '@core/utils/filter'
import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import {showNotify, throwDefaultError} from "@/helpers/helpers";

export default {
  name: 'blocked-user-card',
  props: ['user'],
  components: {
    BAvatar,
    BButton,
    BCol,
    BImg,
    BMedia
  },
  data() {
    return {}
  },
  methods: {
    avatarText(text) {
      return avatarText(text)
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },
    getDateForHuman(timeLeft) {
      if (timeLeft <= 0) {
        return '0 ' + this.$t('seconds')
      }

      let result = ''
      let totalSeconds = timeLeft;

      const days = Math.floor(totalSeconds / (60 * 60 * 24))
      totalSeconds -= days * (60 * 60 * 24)

      const hours = Math.floor(totalSeconds / (60 * 60))
      totalSeconds -= hours * (60 * 60)

      const minutes = Math.floor(totalSeconds / (60))
      totalSeconds -= minutes * 60

      const seconds = Math.floor(totalSeconds % 60)

      if (days > 0) {
        result += days + ` ${this.$t('days')}, `
      }

      if (hours > 0) {
        result += hours + ` ${this.$t('hours')}, `
      }

      if (minutes > 0) {
        result += minutes + ` ${this.$t('minutes')}, `
      }

      if (seconds > 0) {
        result += seconds + ` ${this.$t('seconds')}, `
      }

      return _.trimEnd(result, ', ')
    },
    unBlockUser() {
      this.$emit('setLoading', true)
      useJwt.unBlockUser(this.user.id)
          .then(response => {
            showNotify(this.$toast, 'Kullanıcının engelini kaldırma başarılı', 'success')
            this.$emit('getUsers')
            // this.$emit('setUser', {
            //   ...this.user,
            //   fav: true
            // })
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.$emit('setLoading', false) })
    },
  }
}
</script>

<style lang="scss" scoped>
.blocked-user-card {
  background-color: #F5F8FD;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;

  .blocked-user-card-inner {
    display: flex;
    justify-content: space-between;

    .blocked-user-left {
      display: flex;
      align-items: center;

      .blocked-user-details {
        .blocked-user-link {
          font-family: 'Lexend', sans-serif;
          font-size: 18px;
          font-style: normal;
          color: #000000;
          font-weight: 600;
          line-height: 22px;
        }
      }
      .blocked-user-time {
        font-family: 'Lexend', sans-serif;
        color: #A4A4A4;
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;

      }
    }

    .blocked-user-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
}
</style>
